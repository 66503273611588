<template>
  <v-btn
    id="shareSetupButton"
    color="primary"
    outlined
    @click="actionHandler"
    :loading="loading"
    class="parent"
    absolute
    bottom
    left
  >
    <v-img
      v-if="!completed"
      src="@/assets/images/emojis/slack.svg"
      height="20"
      width="20"
      class="zoom mr-3"
    />
    <animated-tick v-else trigger />
    {{ completed ? $t("deskAssessment.setup.sims.slackDone") : title }}
    <v-menu v-if="authorized" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          class="ml-2"
          color="primary"
          v-bind="attrs"
          v-on="on"
          v-ripple="false"
          >mdi-menu-down</v-icon
        >
      </template>
      <v-list dense>
        <v-list-item @click="sendToAuth">
          <v-list-item-title>
            {{ $t("buttons.changeChannel") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>
</template>

<script>
import { postSlackImage } from "@/customApi";
import AnimatedTick from "@/components/common/AnimatedTick.vue";
import SlackAuth from "./SlackAuth.vue";

export default {
  name: "SlackShareSetup",
  components: {
    AnimatedTick
  },
  mixins: [SlackAuth],
  props: {
    title: String,
    deskPlannerImage: String,
    setupScore: Number,
    assessmentId: String
  },
  data() {
    return {
      completed: false
    };
  },
  watch: {
    authorized(newVal) {
      if (newVal && this.slackRedirectCode) {
        this.setRedirectCode(null);
        this.postImage();
      }
    }
  },
  methods: {
    async actionHandler() {
      this.$emit("buttonClicked");
      if (!this.authorized) {
        this.sendToAuth();
        return;
      }
      await this.postImage();
    },
    async postImage() {
      try {
        this.completed = false;
        this.loading = true;
        let dto = {
          base64Image: this.deskPlannerImage,
          setupScore: this.setupScore,
          assessmentId: this.assessmentId
        };
        await postSlackImage(dto);
        this.completed = true;
        setTimeout(() => (this.completed = false), 2000);
      } catch (err) {
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.zoom {
  transition: all 0.2s ease-in-out;
}
.parent:hover .zoom {
  transform: scale(1.2);
}
</style>
