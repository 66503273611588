var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "stageParentStyle",
      style: _vm.imageBrightness,
      attrs: { id: "stage-parent" },
      on: { dragover: _vm.canvasDraggedOver, drop: _vm.canvasDropImage }
    },
    [
      !_vm.report
        ? _c(
            "p",
            {
              staticClass: "text-body-1 text-end mb-0",
              attrs: { id: "workAreaSelection" }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("deskAssessment.setup.sims.workArea.workingFrom")
                  ) +
                  " "
              ),
              _vm._l(_vm.alternateWorkAreas, function(area, i) {
                return _c(
                  "span",
                  {
                    key: area.value,
                    staticClass: "primary--text",
                    staticStyle: { cursor: "pointer" },
                    attrs: { id: area.value },
                    on: {
                      click: function($event) {
                        return _vm.changeWorkArea(area.value)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        area.text +
                          [i === _vm.alternateWorkAreas.length - 1 ? "" : ", "]
                      )
                    )
                  ]
                )
              })
            ],
            2
          )
        : _vm._e(),
      !!_vm.$refs.stage && _vm.showSlackButton
        ? _c("slack-share-setup", {
            staticStyle: { "z-index": "2" },
            attrs: {
              title: _vm.$t("deskAssessment.setup.sims.slackShare"),
              deskPlannerImage: _vm.$refs.stage.getStage().toDataURL(),
              setupScore: _vm.setupScore,
              assessmentId: _vm.assessmentId
            },
            on: {
              buttonClicked: function($event) {
                return _vm.$emit("saveValues")
              }
            }
          })
        : _vm._e(),
      _c(
        "v-stage",
        {
          ref: "stage",
          attrs: { config: _vm.configKonva },
          on: {
            mousedown: _vm.handleStageMouseDown,
            touchstart: _vm.handleStageMouseDown
          }
        },
        [
          _c(
            "v-layer",
            { ref: "layer" },
            [
              _c("v-rect", {
                attrs: {
                  config: {
                    id: "brightnessLayer",
                    width: 700,
                    height: 600,
                    fill: "white",
                    opacity: 0.25
                  }
                }
              }),
              _c("v-image", {
                ref: "backgroundImage",
                attrs: {
                  config: {
                    id: "backgroundImage",
                    image: this.currentBackgroundImage,
                    width: 700,
                    height: 600
                  }
                }
              }),
              !_vm.report && !_vm.pictureSaving
                ? _c("v-image", {
                    ref: "deleteIconArea",
                    attrs: {
                      config: {
                        id: "deleteIconArea",
                        image: this.currentDeleteImage,
                        width: 120,
                        height: 60,
                        x: 564,
                        y: 525,
                        opacity: 1
                      }
                    }
                  })
                : _vm._e(),
              _vm._l(_vm.deskItems, function(item) {
                return _c("v-image", {
                  key: item.key,
                  ref: "images",
                  refInFor: true,
                  attrs: {
                    config: {
                      id: item.key,
                      image: item.imageSource,
                      width: item.width,
                      height: item.height,
                      draggable: true,
                      x: item.x,
                      y: item.y,
                      offsetX: item.offsetX,
                      offsetY: item.offsetY,
                      rotation: item.rotation
                    }
                  },
                  on: {
                    mouseenter: _vm.imageHoverOverEvent,
                    mouseleave: _vm.imageHoverLeaveEvent,
                    dragend: _vm.handleDragEnd,
                    dragmove: _vm.handleDrag,
                    dragstart: _vm.handleDragStart,
                    transformend: _vm.handleTransformEnd
                  }
                })
              }),
              _c("v-transformer", {
                ref: "transformer",
                attrs: {
                  resizeEnabled: false,
                  rotateEnabled: !_vm.report,
                  borderStrokeWidth: 3,
                  anchorSize: 20,
                  anchorCornerRadius: 5
                }
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }