var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "parent",
      attrs: {
        id: "shareSetupButton",
        color: "primary",
        outlined: "",
        loading: _vm.loading,
        absolute: "",
        bottom: "",
        left: ""
      },
      on: { click: _vm.actionHandler }
    },
    [
      !_vm.completed
        ? _c("v-img", {
            staticClass: "zoom mr-3",
            attrs: {
              src: require("@/assets/images/emojis/slack.svg"),
              height: "20",
              width: "20"
            }
          })
        : _c("animated-tick", { attrs: { trigger: "" } }),
      _vm._v(
        " " +
          _vm._s(
            _vm.completed
              ? _vm.$t("deskAssessment.setup.sims.slackDone")
              : _vm.title
          ) +
          " "
      ),
      _vm.authorized
        ? _c(
            "v-menu",
            {
              attrs: { "offset-x": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            _vm._b(
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple",
                                    value: false,
                                    expression: "false"
                                  }
                                ],
                                staticClass: "ml-2",
                                attrs: { color: "primary" }
                              },
                              "v-icon",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_vm._v("mdi-menu-down")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2243576878
              )
            },
            [
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item",
                    { on: { click: _vm.sendToAuth } },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("buttons.changeChannel")) + " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }