<template>
  <div
    id="stage-parent"
    class="stageParentStyle"
    @dragover="canvasDraggedOver"
    @drop="canvasDropImage"
    :style="imageBrightness"
  >
    <p id="workAreaSelection" class="text-body-1 text-end mb-0" v-if="!report">
      {{ $t("deskAssessment.setup.sims.workArea.workingFrom") }}
      <span
        v-for="(area, i) in alternateWorkAreas"
        :key="area.value"
        :id="area.value"
        class="primary--text"
        style="cursor: pointer"
        @click="changeWorkArea(area.value)"
        >{{
          area.text + [i === alternateWorkAreas.length - 1 ? "" : ", "]
        }}</span
      >
    </p>
    <slack-share-setup
      v-if="!!$refs.stage && showSlackButton"
      :title="$t('deskAssessment.setup.sims.slackShare')"
      :deskPlannerImage="$refs.stage.getStage().toDataURL()"
      :setupScore="setupScore"
      style="z-index: 2"
      @buttonClicked="$emit('saveValues')"
      :assessmentId="assessmentId"
    />
    <v-stage
      ref="stage"
      :config="configKonva"
      @mousedown="handleStageMouseDown"
      @touchstart="handleStageMouseDown"
    >
      <v-layer ref="layer">
        <v-rect
          :config="{
            id: 'brightnessLayer',
            width: 700,
            height: 600,
            fill: 'white',
            opacity: 0.25
          }"
        />
        <v-image
          ref="backgroundImage"
          :config="{
            id: 'backgroundImage',
            image: this.currentBackgroundImage,
            width: 700,
            height: 600
          }"
        />

        <v-image
          v-if="!report && !pictureSaving"
          ref="deleteIconArea"
          :config="{
            id: 'deleteIconArea',
            image: this.currentDeleteImage,
            width: 120,
            height: 60,
            x: 564,
            y: 525,
            opacity: 1
          }"
        />

        <v-image
          v-for="item in deskItems"
          :key="item.key"
          ref="images"
          @mouseenter="imageHoverOverEvent"
          @mouseleave="imageHoverLeaveEvent"
          @dragend="handleDragEnd"
          @dragmove="handleDrag"
          @dragstart="handleDragStart"
          @transformend="handleTransformEnd"
          :config="{
            id: item.key,
            image: item.imageSource,
            width: item.width,
            height: item.height,
            draggable: true,
            x: item.x,
            y: item.y,
            offsetX: item.offsetX,
            offsetY: item.offsetY,
            rotation: item.rotation
          }"
        ></v-image>
        <v-transformer
          ref="transformer"
          :resizeEnabled="false"
          :rotateEnabled="!report"
          :borderStrokeWidth="3"
          :anchorSize="20"
          :anchorCornerRadius="5"
        />
      </v-layer>
    </v-stage>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import DeskAssessmentText from "@/services/deskassessment/desk-assessment-text";
import SlackShareSetup from "@/components/common/slack/SlackShareSetup.vue";
const SimsComponents = require("@/assets/json/DeskAssessment/SimsComponents.json");
const width = 700;
const height = 600;

export default {
  name: "VisualDeskPlanner",
  components: {
    SlackShareSetup
  },
  props: {
    value: Array,
    currentDraggedImageId: String,
    currentDraggedImageVersion: Number,
    currentDraggedImageOffset: Object,
    brightnessValue: Number,
    report: Boolean,
    workArea: Number,
    setupScore: Number,
    assessmentId: String,
    reloadOnUpdate: Boolean
  },
  data() {
    return {
      configKonva: {
        width: width,
        height: height
      },
      deskItems: this.value || [],
      currentBackgroundImage: null,
      currentDeleteImage: null,
      defaultDeleteImage: null,
      dragDeleteImage: null,
      hoverOverDeleteImage: null,
      simsBackgroundImage: null,
      imageLoaded: false,
      height: height,
      pictureSaving: false,
      allWorkAreas: [
        {
          text: this.$t("deskAssessment.setup.sims.workArea.desk"),
          value: "desk"
        },
        {
          text: this.$t("deskAssessment.setup.sims.workArea.sofa"),
          value: "sofa"
        },
        {
          text: this.$t("deskAssessment.setup.sims.workArea.bed"),
          value: "bed"
        }
      ],
      chosenWorkArea:
        this.workArea >= 0
          ? DeskAssessmentText.getWorkAreaFromValue(this.workArea)
          : "desk",
      selectedShapeId: "",
      nonTransformableIds: [
        "backgroundImage",
        "brightnessLayer",
        "deleteIconArea",
        "window"
      ]
    };
  },
  mounted() {
    this.simsImages = SimsComponents.map(a => a.items).flat(1);
    this.loadItems();
    this.loadDeleteImages();
    window.addEventListener("resize", this.setStageScale, { passive: true });
    this.setStageScale();
    this.emitWorkArea();
  },
  watch: {
    deskItems() {
      this.loadBackgroundImages();
      this.assignImageValueToResults();
    },
    imageLoaded(newVal) {
      if (newVal) {
        this.assignImageValueToResults();
      }
    },
    currentBreakpoint() {
      this.setStageScale();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setStageScale, { passive: true });
  },
  methods: {
    loadItems() {
      let loadedItems = [];
      this.deskItems.forEach(item => {
        let imageName = this.simsImages.find(x => x.id === item.id).imageName;
        const image = new window.Image();
        image.src = require("@/assets/images/sims/v" +
          item.version +
          "/" +
          imageName);
        item.imageSource = image;
        image.onload = () => {
          loadedItems.push(item);
          loadedItems = this.sortItems(loadedItems);
        };
      });
      this.deskItems = loadedItems;
    },
    sortItems(items) {
      return items.sort((a, b) => {
        return a.zIndex - b.zIndex;
      });
    },
    loadBackgroundImages() {
      let backgroundImage = new window.Image();
      let language = this._i18n.locale.slice(0, 2);
      backgroundImage.src =
        this.deskItems.length > 0 || this.value.length > 0
          ? require("@/assets/images/sims/v1/" +
              this.chosenWorkArea +
              "NoText.png")
          : require("@/assets/images/sims/v1/" +
              this.chosenWorkArea +
              "Text" +
              language +
              ".png");
      backgroundImage.onload = () => {
        this.imageLoaded = false;
        this.currentBackgroundImage = backgroundImage;
        setTimeout(() => (this.imageLoaded = true), 200);
      };
    },
    loadDeleteImages() {
      let regularDeleteImage = new window.Image();
      regularDeleteImage.src = require("@/assets/images/sims/deleteIcons/deleteItemIcon.png");
      regularDeleteImage.onload = () => {
        this.currentDeleteImage = regularDeleteImage;
        this.defaultDeleteImage = regularDeleteImage;
      };

      let dragDeleteImage = new window.Image();
      dragDeleteImage.src = require("@/assets/images/sims/deleteIcons/deleteItemIconWithBorder.png");
      dragDeleteImage.onload = () => {
        this.dragDeleteImage = dragDeleteImage;
      };

      let hoverDeleteImage = new window.Image();
      hoverDeleteImage.src = require("@/assets/images/sims/deleteIcons/hoverOverDeleteArea.png");
      hoverDeleteImage.onload = () => {
        this.hoverOverDeleteImage = hoverDeleteImage;
      };
    },
    handleDragStart(e) {
      if (this.report) {
        e.target.stopDrag();
      }
    },
    handleDragEnd(e) {
      if (this.report) {
        return;
      }

      if (this.isImageOffCanvas(e.target) || this.isMouseInDeleteZone()) {
        this.deleteImage(e.target.attrs.id);
      } else {
        this.updateDroppedImage(e.target);
      }

      this.updateDeleteArea(true, e.target);
      this.$emit("input", this.deskItems);
      this.$emit("calculateResults");
    },
    handleDrag(e) {
      if (this.report) {
        return;
      }
      if (e.target.attrs.id.includes("window")) {
        this.rotateWindowOnDrag(e.target);
      }
      this.updateDeleteArea(false, e.target);
    },
    updateDeleteArea(hide, item) {
      if (hide) {
        this.currentDeleteImage = this.defaultDeleteImage;
        item.setAttr("opacity", 1);
      } else if (this.isMouseInDeleteZone()) {
        this.currentDeleteImage = this.hoverOverDeleteImage;
        item.setAttr("opacity", 0.5);
      } else {
        this.currentDeleteImage = this.dragDeleteImage;
        item.setAttr("opacity", 1);
      }
    },
    canvasDraggedOver(e) {
      e.preventDefault(); // !important - from Konva documentation need to prevent default handling
    },
    // increase scale of images slightly when hovered over
    imageHoverOverEvent(e) {
      if (this.report) {
        return;
      }
      this.hoverOverEffect(e, true);
    },
    // decrease scale of images slightly when hover over lost
    imageHoverLeaveEvent(e) {
      if (this.report) {
        return;
      }
      this.hoverOverEffect(e, false);
    },
    // if hover enter is true then increase scale, else decrease
    hoverOverEffect(e, hoverEnter) {
      e.target.setAttr("scaleX", hoverEnter ? 1.1 : 1);
      e.target.setAttr("scaleY", hoverEnter ? 1.1 : 1);
      this.$refs.layer.getNode().batchDraw();
    },
    rotateWindowOnDrag(image) {
      // relativePointerPosition used for position image after rotate
      var relativePointerPosition = this.getRelativePointerPosition(
        this.$refs.backgroundImage.getNode()
      );

      // get pointer position relative to image so same place on window is dragged
      var xOffset = relativePointerPosition.x - image.attrs.x;
      var yOffset = relativePointerPosition.y - image.attrs.y;

      // Have to stop and start drag in order for position to update correctly (otherwise automatically gets far away from mouse again)
      var windowArea = this.getWindowArea(relativePointerPosition);
      var windowIsVertical = image.attrs.rotation == 90;

      if (windowArea === "vertical" && !windowIsVertical) {
        image.stopDrag();
        image.setAttr("rotation", 90);
        image.position({
          x: relativePointerPosition.x + yOffset,
          y: relativePointerPosition.y - xOffset
        });
        image.startDrag();
      } else if (windowArea === "horizontal" && windowIsVertical) {
        image.stopDrag();
        image.setAttr("rotation", 0);
        image.position({
          x: relativePointerPosition.x - yOffset,
          y: relativePointerPosition.y + xOffset
        });
        image.startDrag();
      }

      // Need to update the desk item array's rotation value for when images refresh
      var itemIndex = this.deskItems.findIndex(i => i.key == image.attrs.id);
      if (
        this.deskItems[itemIndex] &&
        this.deskItems[itemIndex].id === "window"
      ) {
        var selectImage = this.deskItems.find(i => i.key == image.attrs.id);
        selectImage.rotation = image.attrs.rotation;
      }
    },
    getWindowArea(currentPos) {
      // get x/y distance from edge to desk based on ratio calculated from image
      var xDistanceFromEdge = width * 0.186;
      var yDistanceFromEdge = height * 0.294;

      var windowAtSideOfDesk =
        currentPos.x < xDistanceFromEdge ||
        currentPos.x > width - xDistanceFromEdge;
      var windowAboveDesk = currentPos.y < yDistanceFromEdge;
      var windowBelowDesk = currentPos.y > height - yDistanceFromEdge;

      if (windowAtSideOfDesk && !windowAboveDesk && !windowBelowDesk) {
        return "vertical";
      } else if (!windowAtSideOfDesk && (windowAboveDesk || windowBelowDesk)) {
        return "horizontal";
      }

      return "none";
    },
    // checks if whole image is off canvas
    isImageOffCanvas(image) {
      var imageSize = this.imageSizeAfterRotation(
        [image.attrs.width, image.attrs.height],
        image.attrs.rotation
      );

      if (
        image.attrs.x > width ||
        image.attrs.y > height ||
        image.attrs.x + imageSize[0] < 0 ||
        image.attrs.y + imageSize[1] < 0
      ) {
        return true;
      }
      return false;
    },
    imageSizeAfterRotation(size, degrees) {
      degrees = degrees % 180;
      if (degrees < 0) {
        degrees = 180 + degrees;
      }
      if (degrees >= 90) {
        size = [size[1], size[0]];
        degrees = degrees - 90;
      }
      if (degrees === 0) {
        return size;
      }
      const radians = (degrees * Math.PI) / 180;
      const width = size[0] * Math.cos(radians) + size[1] * Math.sin(radians);
      const height = size[0] * Math.sin(radians) + size[1] * Math.cos(radians);
      return [width, height];
    },
    isMouseInsideCanvas() {
      var pointerPosition = this.getRelativePointerPosition(
        this.$refs.backgroundImage.getNode()
      );

      if (
        pointerPosition.x < width &&
        pointerPosition.y < height &&
        pointerPosition.x > 0 &&
        pointerPosition.y > 0
      ) {
        return true;
      }
      return false;
    },
    isMouseInDeleteZone() {
      var pointerPosition = this.getRelativePointerPosition(
        this.$refs.backgroundImage.getNode()
      );

      var deleteArea = this.$refs.deleteIconArea.getNode();
      if (
        pointerPosition.x > deleteArea.attrs.x &&
        pointerPosition.y > deleteArea.attrs.y &&
        pointerPosition.x < deleteArea.attrs.x + deleteArea.attrs.width &&
        pointerPosition.y < deleteArea.attrs.y + deleteArea.attrs.height
      ) {
        return true;
      }
      return false;
    },
    // remove items with id from dropped images array
    deleteImage(id) {
      for (var i = 0; i < this.deskItems.length; i++) {
        if (this.deskItems[i].key === id) {
          this.deskItems.splice(i, 1);
          i--;
        }
      }
      this.deselectTransformer();
      this.$emit("calculateResults");
    },
    canvasDropImage(e) {
      e.preventDefault();
      const stage = this.$refs.stage.getNode();
      stage.setPointersPositions(e);

      if (!this.isMouseInsideCanvas()) {
        return;
      }
      this.addCurrentItemToDesk("pointer");
    },
    updateDroppedImage(image) {
      var index = this.deskItems.findIndex(i => i.key == image.attrs.id);
      if (index >= 0) {
        this.deskItems[index].x = image.attrs.x;
        this.deskItems[index].y = image.attrs.y;
      }
      this.assignImageValueToResults();
    },
    addCurrentItemToDesk(dropLocation) {
      var selectImage = this.simsImages.find(
        i => i.id == this.currentDraggedImageId
      );

      // up the ID value by 1 if it already exists in array - allows for multiple items of same type
      var currentId = this.currentDraggedImageId;
      var idNumber =
        this.deskItems.filter(function (entry) {
          return entry.id.includes(currentId);
        }).length + 1;

      // create new image object and add to dropped images array
      const image = new window.Image();
      let version = this.currentDraggedImageVersion;
      image.src = require("@/assets/images/sims/v" +
        version +
        "/" +
        selectImage.imageName);

      var imageOffsetX = selectImage.width / 2;
      var imageOffsetY = selectImage.height / 2;
      var imageRotation = 0;

      let location;

      if (dropLocation === "pointer") {
        // Check if window rotation is required - if so flip position of x & y and set rotation value to 90
        location = this.getRelativePointerPosition(
          this.$refs.backgroundImage.getNode()
        );
        if (
          this.currentDraggedImageId == "window" &&
          this.getWindowArea(location) === "vertical"
        ) {
          imageRotation = 90;
          location.x += imageOffsetY - this.currentDraggedImageOffset.y;
          location.y += imageOffsetX - this.currentDraggedImageOffset.x;
        } else {
          location.x += imageOffsetX - this.currentDraggedImageOffset.x;
          location.y += imageOffsetY - this.currentDraggedImageOffset.y;
        }
      } else if (dropLocation === "default") {
        let overlapMargin = idNumber > 1 ? idNumber * 10 : 0;
        location = {
          x: selectImage.defaultPosition.x + overlapMargin,
          y: selectImage.defaultPosition.y + overlapMargin
        };
      }

      image.onload = () => {
        this.deskItems.push({
          id: this.currentDraggedImageId,
          key: this.currentDraggedImageId + "_" + idNumber,
          imageSource: image,
          version: version,
          x: location.x,
          y: location.y,
          width: selectImage.width,
          height: selectImage.height,
          rotation: imageRotation,
          offsetX: imageOffsetX,
          offsetY: imageOffsetY,
          zIndex: this.deskItems.length
        });
        this.$emit("input", this.deskItems);
        this.$emit("calculateResults");
      };
    },
    getRelativePointerPosition(node) {
      var transform = node.getAbsoluteTransform().copy();
      // to detect relative position we need to invert transform
      transform.invert();

      // get pointer (say mouse or touch) position
      var pos = node.getStage().getPointerPosition();

      // now we can find relative point
      return transform.point(pos);
    },
    setStageScale() {
      const stage = this.$refs.stage.getNode();
      Vue.nextTick(function () {
        var container = document.querySelector("#stage-parent");
        var containerWidth = container.offsetWidth;
        var scale = containerWidth / width;

        stage.width(width * scale);
        stage.height(height * scale);
        stage.scale({ x: scale, y: scale });
        stage.draw();
      });
    },
    changeWorkArea(area) {
      this.chosenWorkArea = area;
      this.loadBackgroundImages();
      this.emitWorkArea();
      this.assignImageValueToResults();
    },
    emitWorkArea() {
      let value = DeskAssessmentText.getValueFromWorkArea(this.chosenWorkArea);
      this.$emit("workArea", value);
    },
    handleTransformEnd(e) {
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      var selectImage = this.deskItems.find(i => i.key == this.selectedShapeId);
      if (selectImage) {
        selectImage.rotation = e.target.rotation();
        this.$emit("input", this.deskItems);
        this.assignImageValueToResults();
        this.$emit("calculateResults");
      }
    },
    handleStageMouseDown(e) {
      const targetId = e.target.attrs.id;
      // if we select a konva item such as anchor or are on report just return
      if (!targetId || this.report) {
        return;
      }
      var isTargetNonTransformable = this.nonTransformableIds.find(id =>
        targetId.includes(id)
      );
      // clicked on stage - clear selection
      if (e.target === e.target.getStage() || isTargetNonTransformable) {
        this.deselectTransformer();
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent().className === "Transformer";
      if (clickedOnTransformer) {
        return;
      }

      // find clicked rect by its name

      const rect = this.deskItems.find(i => i.key === targetId);
      if (!rect) {
        this.deselectTransformer();
        return;
      }
      this.selectedShapeId = targetId;
      this.updateTransformer();
    },
    updateTransformer() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const { selectedShapeId } = this;

      const selectedNode = stage.findOne("#" + selectedShapeId);
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }
      if (selectedNode && selectedShapeId) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
      stage.draw();
    },
    deselectTransformer() {
      this.selectedShapeId = "";
      this.updateTransformer();
    },
    assignImageValueToResults() {
      this.pictureSaving = true;
      const transformerNode = this.$refs.transformer.getNode();
      transformerNode.hide();
      Vue.nextTick(() => {
        let imageValue =
          this.deskItems.length === 0
            ? null
            : this.$refs.stage.getStage().toDataURL();
        this.$emit("updateImage", imageValue);
        this.pictureSaving = false;
        transformerNode.show();
      });
    }
  },
  computed: {
    ...mapGetters(["signedIn", "slackDisabled"]),
    currentBreakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    showSlackButton() {
      return false; // temp solution until we fix redirects on first auth
      return (
        !this.slackDisabled &&
        this.$route.path.includes("deskassessment") &&
        this.imageLoaded
      );
    },
    // done via CSS brightness as konva filters caused images to loose too much quality
    imageBrightness() {
      switch (this.brightnessValue) {
        case -2:
          return "filter: brightness(60%)";
        case -1:
          return "filter: brightness(70%)";
        case 1:
          return "filter: brightness(90%)";
        case 2:
          return "filter: brightness(100%)";
      }
      return "filter: brightness(80%);";
    },
    alternateWorkAreas() {
      return this.allWorkAreas.filter(x => x.text !== this.chosenWorkArea);
    }
  }
};
</script>

<style scoped>
.stageParentStyle {
  min-width: 100px;
  min-height: 100px;
  max-width: 700px;
  max-height: 650px;
  margin-left: auto;
  margin-right: auto;
}
</style>
